import React from "react";

import Layout from "../../components/Layout";
import { compose } from "recompose";
import {
  withAuthorization,
  withActiveSubscription,
} from "../../components/Session";
import UserHabits from "../../components/UserHabits";

const BasixIndexPageBase = ({ pageContext }) => {
  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <UserHabits />
          </div>
        </div>
      </div>
    </section>
  );
};

const condition = (authUser) => !!authUser;

const BasixIndexPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(BasixIndexPageBase);

const BasixIndexPageFinal = ({ pageContext }) => (
  <Layout>
    <BasixIndexPage pageContext={pageContext} />
  </Layout>
);

export default BasixIndexPageFinal;
